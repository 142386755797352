import react, { useEffect, useState } from "react";
import i18next from 'i18next';

const SamsungNav = () => {

    const [menu, setMenu] = useState("");

    const openMenu = () => {
        if (menu == "open") {
            setMenu("");
            return;
        }
        setMenu("open");
    }

    return (
        <div style = {{marginBottom: "20px"}}>
           <nav class="gnb__nav" role="navigation">
            <div class="gnb__bar">
                <div class="gnb__bar-inner">
                <div>
                    <a href="https://www.samsung.com/hk/" class="gnb__logo"
                    ><span class="hidden">Samsung</span></a
                    >
                </div>

                <div class="gnb__mobile-menu">
                    <a
                    class="gnb__cart-btn js-layer-open"
                    data-translateurl="_cartUrl"
                    href="https://www.samsung.com/hk/"
                    role="button"
                    data-div-id="#layerEmptyCart"
                    ><span class="hidden">Cart</span
                    ><svg class="icon" id="cart-bold" viewBox="0 0 96 96">
                        <path
                        d="M72.848 70.25c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm-35 0c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11c0-6.074 4.926-11 11-11zm35 7a4 4 0 10.002 8.001 4 4 0 00-.002-8.001zm-35 0a4 4 0 100 8 4 4 0 000-8zM13.892 3.75c2.287 0 4.376 1.55 5.058 3.72l.064.22 2.97 11.187h68.128a4.727 4.727 0 014.661 5.786l-.048.2-9.559 36.291c-.583 2.216-2.618 3.842-4.894 3.944l-.228.005H30.06c-2.287 0-4.377-1.55-5.06-3.72l-.063-.219-13.124-49.413-10.7.006-.004-8 12.782-.007zm72.03 23.127H24.108l8.027 30.226H77.96l7.962-30.226z"
                        ></path></svg></a
                    >

                    <div
                        onClick = {openMenu}
                        id = "menu-samsung"
                        class="gnb__menu-btn"
                        aria-haspopup="true"
                        role="button"
                    ><span class="hidden">Open the Menu</span
                    ><svg class="icon" id="menu-bold" viewBox="0 0 96 96">
                        <path
                        d="M83 70v8H13v-8h70zm0-26v8H13v-8h70zm0-26v8H13v-8h70z"
                        ></path>
                        </svg></div>
                </div>
                <div class={`gnb__menu-wrap ${menu}`}>
                    <div class="gnb__depth1-container">
                    <div class= {`gnb__main ${menu}`}>
                        <ul class= {`gnb__depth1 ${menu}`} role="menubar">
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            // href="https://www.samsung.com/hk/offer/galaxyunpack/"
                            href = "https://www.samsung.com/hk/offer/online-shop/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span class="gnb__depth1-link-text"
                                >{i18next.t("網上商店")}</span >
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/mobile/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                >{i18next.t("流動產品")}</span >
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/tvs/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span class="gnb__depth1-link-text"
                                >{i18next.t("電視影音")}</span>
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/home-appliances/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span class="gnb__depth1-link-text"
                                >{i18next.t("生活家電")}</span>
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/computers/all-computers/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                >{i18next.t("電腦及周邊")}</span >
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/monitors/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                >{i18next.t("顯示器")}</span >
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/mobile-accessories/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                >{i18next.t("配件")}</span>
                            </a>
                        </li>
                        <li class="gnb__depth1-menu" role="presentation">
                            <a
                            class="gnb__depth1-link"
                            data-translateurl="_smartThingsUrl"
                            href="https://www.samsung.com/hk/smartthings/do-the-smartthings/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                data-translate="_smartThings"
                                >SmartThings</span >
                            </a>
                        </li>
                        </ul>
                    </div>

                    <div class="gnb__sub">
                        <ul class={`gnb__depth1 ${menu}`} role="menubar">
                        <li
                            class="gnb__depth1-menu has-depth-menu"
                            role="presentation"
                        >
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/offer/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                >{i18next.t("優惠/活動")}</span >
                            </a>
                        </li>
                        <li
                            class="gnb__depth1-menu has-depth-menu"
                            role="presentation"
                        >
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/support/"
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                            >{i18next.t("服務支援")}</span>
                            </a>
                        </li>
                        <li
                            class="gnb__depth1-menu has-depth-menu"
                            role="presentation"
                        >
                            <a
                            class="gnb__depth1-link"
                            href="https://www.samsung.com/hk/business/ "
                            role="menuitem"
                            aria-haspopup="true"
                            tabindex=""
                            >
                            <span
                                class="gnb__depth1-link-text"
                                >{i18next.t("商務採購")}</span>
                            </a>
                        </li>
                        </ul>
                        <ul class="gnb__utility">
                        <li class="gnb__cart">
                            <a
                            data-translateurl="_cartUrl"
                            href="https://www.samsung.com/hk/"
                            class="gnb__cart-btn"
                            role="link"
                            ><span class="hidden">Cart</span>
                            <svg
                                class="icon"
                                id="cart-bold"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 96 96"
                            >
                                <path
                                d="M72.848 70.25c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11 4.925-11 11-11zm-35 0c6.075 0 11 4.925 11 11s-4.925 11-11 11-11-4.925-11-11c0-6.074 4.926-11 11-11zm35 7a4 4 0 10.002 8.001 4 4 0 00-.002-8.001zm-35 0a4 4 0 100 8 4 4 0 000-8zM13.892 3.75c2.287 0 4.376 1.55 5.058 3.72l.064.22 2.97 11.187h68.128a4.727 4.727 0 014.661 5.786l-.048.2-9.559 36.291c-.583 2.216-2.618 3.842-4.894 3.944l-.228.005H30.06c-2.287 0-4.377-1.55-5.06-3.72l-.063-.219-13.124-49.413-10.7.006-.004-8 12.782-.007zm72.03 23.127H24.108l8.027 30.226H77.96l7.962-30.226z"
                                ></path>
                            </svg>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div class="gnb__menu-close" onClick = {openMenu}>
                    <span class="hidden">Close Menu</span>
                    <svg
                        class="icon"
                        id="delete-bold"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 96 96"
                    >
                        <path
                        d="M79.17 11.17L48 42.34 16.83 11.17l-5.66 5.66L42.34 48 11.17 79.17l5.66 5.66L48 53.66l31.17 31.17 5.66-5.66L53.66 48l31.17-31.17z"
                        ></path>
                    </svg>
                    </div>
                </div>
                </div>
            </div>
            </nav>
        </div>
    )
}

export default SamsungNav;