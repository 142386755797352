import React from "react";
import "./footer.css";
import "./nav.css";
import i18next from 'i18next';

const SamsungFooter = () => {
    
    const changeLang = (lang) => {
        window.location.href="/?lang=" + lang
    }

    const showLang = () => {
        let menu = document.getElementById("samsung-menu");
        menu.style.display = "block";
    }

    return (
        <div>
            <div id="static_footer">
                <div class="footer">
                    <div class="footer-bottom">
                    <div class="footer-copyright-wrap">
                        <div class="footer-copyright-align">
                        <p class="footer-copyright">
                            {i18next.t("© 1995-2023 三星電子香港有限公司 版權所有")}
                        </p>
                        <div class="footer-legal">
                        </div>
                        </div>
                        <div class="footer-local-logo">
                        <div class="footer-local-logo--wrap">
                        </div>
                        </div>
                    </div>
                    <div class="footer-language-wrap">
                        <div class="footer-sns">
                        <span class="footer-sns__title">STAY IN THE LOOP?</span>
                        {/* overwritte padding-left saved in orginal file */}
                        <ul class="footer-sns__list" style = {{paddingLeft: "0px"}}>
                            <li class="footer-sns__item">
                            <a
                                class="footer-sns__link"
                                href="https://www.facebook.com/SamsungHK/"
                                target="_blank"
                                title="Open in a new window"
                                ><div class="image">
                                <img
                                    class="image__preview"
                                    src="https://twsamsungcampaign.azureedge.net/common_api/images/icon-bold-social-facebook.svg"
                                    style = {{visibility: "hidden"}}
                                /><img
                                    class="image__main"
                                    src="https://twsamsungcampaign.azureedge.net/common_api/images/icon-bold-social-facebook.svg"
                                    style = {{visibility: "visible"}}
                                /></div
                            ></a>
                            </li>
                            <li class="footer-sns__item">
                            <a
                                class="footer-sns__link"
                                href="https://www.instagram.com/samsung_hk/"
                                target="_blank"
                                title="Open in a new window"
                                ><div class="image">
                                <img
                                    class="image__preview"
                                    src="https://twsamsungcampaign.azureedge.net/common_api/images/icon-bold-social-instagram.svg"
                                    style = {{visibility: "hidden"}}
                                /><img
                                    class="image__main"
                                    src="https://twsamsungcampaign.azureedge.net/common_api/images/icon-bold-social-instagram.svg"
                                    style = {{visibility: "visible"}}
                                /></div
                            ></a>
                            </li>
                            <li class="footer-sns__item">
                            <a
                                class="footer-sns__link"
                                href="https://www.youtube.com/user/SamsungHongKong"
                                target="_blank"
                                title="Open in a new window"
                                ><div class="image">
                                <img
                                    class="image__preview"
                                    src="https://twsamsungcampaign.azureedge.net/common_api/images/icon-bold-social-youtube.svg"
                                    style = {{visibility: "hidden"}}
                                /><img
                                    class="image__main"
                                    src="https://twsamsungcampaign.azureedge.net/common_api/images/icon-bold-social-youtube.svg"
                                    style = {{visibility: "visible"}}
                                /></div
                            ></a>
                            </li>
                        </ul>
                        </div>
                        {/* <div onClick = {showLang} class="footer-language">
                            <a
                                data-translate="_lang"
                                class="footer-language__anchor"
                                href="javascript:void(0);"
                                role="button"
                                >{i18next.t('香港/繁體中文')}</a
                            >
                            <ul id = "samsung-menu" class="gb-footer__lang-select">
                                <li>
                                <a
                                    href="javascript:void 0"
                                    class="gb-footer__select-link tc-lang"
                                    role = "button"
                                    title="Visit your country's&nbsp;or&nbsp;region's&nbsp;homepage"
                                >
                                    <span onClick={()=>changeLang('zh')}>香港/繁體中文</span>
                                </a>
                                </li>
                                <li>
                                <a
                                    href="javascript:void 0"
                                    class="gb-footer__select-link en-lang"
                                    role = "button"
                                    title="Visit your country's&nbsp;or&nbsp;region's&nbsp;homepage"
                                >
                                    <span onClick={()=>changeLang('en')}> Hong Kong/English </span>
                                </a>
                                </li>
                            </ul>
                        <div class="footer-language__panel" style = {{display: "none"}}>
                            <div class="footer-language__dimmed" aria-hidden="true"></div>
                            <div class="footer-language__content">
                            <ul class="footer-language__list">
                            </ul>
                            <a
                                class="footer-language__link"
                                href="/hk/function/ipredirection/ipredirectionLocalList/"
                                >香港</a
                            >
                            </div>
                        </div>
                        </div> */}
                        <div class="footer-terms">
                        <ul class="footer-terms__list">
                            <li class="footer-terms__item">
                            <a
                                class="footer-terms__link"
                                href="https://www.samsung.com/hk/info/sitemap/"
                                target="_self"
                                >{i18next.t("網站導覽")}</a
                            >
                            </li>
                            <li class="footer-terms__item">
                            <a
                                class="footer-terms__link"
                                href="https://www.samsung.com/hk/info/privacy/"
                                target="_self"
                                >{i18next.t("私隱政策")}</a
                            >
                            </li>
                            <li class="footer-terms__item">
                            <a
                                class="footer-terms__link"
                                href="https://www.samsung.com/hk/info/legal/"
                                target="_self"
                                >{i18next.t("使用條款")}</a
                            >
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SamsungFooter;