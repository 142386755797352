import React from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import App from "./App";
import UplaodImage from "./UploadImage";
import UplaodImageEng from "./UploadImageEng";

const Router = () => {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <App/>,
        },
        {
            path: "/uploadch",
            element: <UplaodImage></UplaodImage>
        },
        {
            path: "/uploadeng",
            element: <UplaodImageEng></UplaodImageEng>
        },
    ]);
    

    return (
        <RouterProvider router={router} />
    )
}

export default Router;