import React, { useEffect, useState, useRef } from "react";
import SamsungFooter from "./SamsungFooter";
import SamsungNav from "./SamsungNav";
import "./SuccessUpload.css";

const SuccessUpload = (props) => {
    
    const share_msg = "您希望未來的大自然係點? 即到活動網站下載畫紙，提交「您」想大自然，有機會參與Samsung中環娛樂行大型屏幕3D動畫製作!"
    //const share_link = "https://d1py5h0zpdkzrc.cloudfront.net/"
    const share_link = "https://sustainability-samsunghk.com/"
    
    const [image, setImage] = useState(props.image);
    const [type, setType] = useState(props.type);

    const isMobile = () => {
        let a = navigator.userAgent||navigator.vendor||window.opera;
        let check = false;
        if(
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) {
             check = true;
        }
        console.log(check)
        return check
    };
  

    const shareFile = (file, title, text) => {
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          navigator
            .share({
              files: [file],
              title: title,
              text: title + " " + text,
              url: text
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => console.log("Sharing failed", error));
        } else {
            alert(`Your system doesn't support sharing files.`);
        }
      };

    const share = async() => {
        await fetch(image).then(r => r.blob()).then(
            blobFile => {
                let file = new File([blobFile], "filename."+type.split('/')[1], { type: type })
                console.log(file)
                shareFile(file, share_msg, share_link);
            }
        )
      };


    return (
        <>
            <div className="container-fluid success-upload">
           
                <div className="success-image">
                    
                    <div style = {{width: "100%"}}>
                        <img src = {`${props.image}`} style = {{width: "100%", height: "100%", objectFit: "cover", borderRadius: "20px"}}></img>
                    </div>

                    <div className = "info-upload-container">
                        <p className = "upload-text-sucess-chinese">
                            {
                                (props.lang == "ch") ?
                                '成功上載' : 'Upload Successfully'    
                            }
                        </p>
                    </div>
                    
                       
                    <div className = "social-media-container" style={{marginTop:20}}>

                        <div className = "social-media">
                            {isMobile() ?
                            (<div className = "socials go-back" style={{background:'white',border:1, borderStyle: 'solid',borderColor:'black'}}>
                                <a onClick={share}>
                                    {
                                        (props.lang == "ch") ? 
                                        '分享' : 'Share'
                                    }
                                </a>
                            </div>) : (
                                <div className = "socials go-back" style={{background:'white',border:1, borderStyle: 'solid',borderColor:'black'}}>
                                    <a style={{color:'black'}} href={"http://www.facebook.com/sharer.php?u=" + share_link + "&title=" + share_msg} target="_blank ">
                                        {
                                            (props.lang == "ch") ? 
                                            '分享' : 'Share'
                                        }
                                    </a>
                                </div>
                            )
                            }
                        </div>

                        <button className = "go-back-container">
                            <div className = "go-back" onClick = {() => props.goBack()}>
                                <p>
                                    {
                                        (props.lang == "ch") ?
                                        '返回' :  'Return' 
                                    }
                                </p>
                            </div>
                        </button>

                    </div>

                </div>
             
            </div>  
        </>
    )
}

export default SuccessUpload;