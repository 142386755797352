import React from "react";
import "./CustomFooter.css";

import i18next from 'i18next';


const Footer = () => {

    return (
        <div className="container custom-footer-container">
          <div className = "footer-text-wrapper">
        
            <p className="custom-footer-text" style = {{fontWeight: "bolder"}}>
                {i18next.t('改變 從日常做起')}
            </p>
            <p className="custom-footer-text" style = {{fontWeight: "bolder"}}>
                #ChangesFromSmallSteps
            </p>

            <div className = "pez-aleta-2">
                <img src = {require("./assets/anguila.png")} style = {{width: "100%", height: "100%"}}></img>
            </div>

            <div className = "pez-gordito-2">
                <img src = {require("./assets/beso.png")} style = {{width: "100%", height: "100%"}}></img>
            </div>


          </div>
        
        </div>
    )
}

export default Footer;