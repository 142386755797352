import React from "react";
import './App.css';
import './Experience.css';
import Carousel from 'react-bootstrap/Carousel';

const WorkShopCarousel2 = (props) => {
    
    const images = [
        1,2,3,4,5,6,
        7,8,9,10,11,12,
        13,14,15,16,17,18,
        19,20,21,22,23,24
    ];

    return (
        <div 
            className = "container-fluid experience-container-new"
            ref = {props.work_ref}
        >
            <div className = "title-experience-container">
                {/* <p className="title-ex-text" style = {{fontWeight: "bolder"}}> 
                    繪畫工作坊
                </p> */}
                <p className="title-ex-text" style = {{fontWeight: "bolder"}}> 
                    繪畫工作坊
                </p>
                </div>

                <div className = "experience-wrapper">
                
                <img className = "estrella" src = {require("./assets/estrella.png")} style = {{zIndex: "1000"}}></img>
                
                <div className = "experience-image">
                    <Carousel
                        indicators = {false}
                        interval = {4000}
                        indicatorLabels = {false}
                        controls = {true}
                        prevIcon = {
                            <img src = {require("./assets/right.png")} style = {{width: "30px", height: "30px"}}></img>
                        }
                        nextIcon = {
                            <img src = {require("./assets/left.png")} style = {{width: "30px", height: "30px"}}></img>
                        }
                        style = {{
                            width: "100%"
                        }}
                        defaultActiveIndex = {0}
                    >
                    {
                        images.map((item, index) => {
                            return (
                                <Carousel.Item>
                                    <div className="carousel-image-height" style = {{overflow: "hidden", width: "100%", backgroundImage: `url(https://d1py5h0zpdkzrc.cloudfront.net/ww${item}.jpg)`, backgroundSize: "cover", backgroundPosition: "center center", position: "relative"}}>
                                
                                    </div>
                                </Carousel.Item>              
                            )
                        })
                    }
                    </Carousel> 

                    {/* <img src = {require("./assets/child_tablet.jpg")}></img> */}
                </div>
                
                <div className = "experience-text">

                    <div className = "experience-text-wrapper">

                    <div className = "inner-experience-wrapper">
                    
                        <div class = "tablet-text">
                            <img src = {require("./assets/ostra.png")}></img>
                            <p className = "tab-content-text">
                                Samsung 聯同本地環保組織舉辦工作坊，參加者在專業插畫師現場指導下，用 Samsung 最新的 Galaxy Tab S9 系列，用藝術為大自然發聲 ，畫出「您」想大自然景色。
                            </p>
                        </div>
              
                    </div>
                
                    </div>

                </div>
            </div>

        </div>

    )
}

export default WorkShopCarousel2;