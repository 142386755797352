import React, { useEffect, useState, useRef } from "react";
import SamsungFooter from "./SamsungFooter";
import SamsungNav from "./SamsungNav";
import "./Upload.css";
import SuccessUpload from "./SuccessUpload";
import { useNavigate } from "react-router-dom";

const UplaodImage = () => {

    const MAX_FILE_SIZE = 5120; // 5MB
    const navigate = useNavigate();

    const [dragActive, setDragActive] = useState(false);
    const [image, setImage] = useState("");
    const [file, setFile] = useState(null);
    const [success, setSuccess] = useState(false);
    const [uploading, setUploading] = useState(false);

    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
        } else if (e.type === "dragleave") {
        setDragActive(false);
        }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (e.dataTransfer.files[0].name.match(/\.(jpg|jpeg|png|)$/i)) {
                var reader = URL.createObjectURL(e.dataTransfer.files[0]);
                setImage(reader);
                setFile(e.dataTransfer.files[0]);
                return;
            }
            alert("Only images supported")
        }
    };
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].name.match(/\.(jpg|jpeg|png|)$/i)) {
                var reader = URL.createObjectURL(e.target.files[0]);
                setImage(reader);
                setFile(e.target.files[0]);
                return;
            }
            alert("Only images supported")
        }   
    };
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };
    
    const sendFile = () => {
        if (file == null) {
            alert("Please upload a file");
            return;
        }

        const fileSizeKiloBytes = file.size / 1024;

        if(fileSizeKiloBytes > MAX_FILE_SIZE){
            alert("File size is greater than maximum limit");
            return;
        }

        setUploading(true);

        setTimeout(() => {
            fetch('https://fw1uxa8pj9.execute-api.ap-east-1.amazonaws.com/v1/upload', {
                method: 'POST',
                body: file,
                // 👇 Set headers manually for single file upload
                headers: {
                    'Content-type': file.type,
                    'Content-length': `${file.size}`, // 👈 Headers need to be a string
                },
                mode: 'cors',
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.statusCode == 200) {
                    setSuccess(true);
                }
            })
            .catch((err) => alert("Error when uploading file, server side"));
        }, 300)
    
    }

    const goBack = () => {
        setSuccess(false);
        setImage("");
        setFile(null);
        navigate("/");
    }
    return (
        <>
            <SamsungNav></SamsungNav>

            {
            (success) ? <SuccessUpload image = {image} type={file.type} lang = "ch" goBack = {goBack}></SuccessUpload> :
                <div className="container-fluid upload">

                    {/* <video id = "video" width="100%" height = "100%" muted autoPlay loop muted playsInline style = {{objectFit: "cover", position: "absolute", zIndex: -1}}>
                        <source src = {require("./assets/video/water.mp4")} type="video/mp4"/>
                    </video> */}
                 
                    <div style = {{width: "100%", height: "100%", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", posiiton: "relative"}}>
                       
                        <div className = "image1-upload">
                            <img src = {require("./assets/anguila.png")} style = {{width: "100%", height: "100%"}}></img>
                        </div>
                        
                        <div className = "image2-upload">
                            <img src = {require("./assets/pez.png")} style = {{width: "100%", height: "100%"}}></img>
                        </div>
                        
                        <div className = "image3-upload">
                            <img src = {require("./assets/pez_gordo.png")} style = {{width: "100%", height: "100%"}}></img>
                        </div>
                    
                 
                        <div className="upload-container" style = {{overflow: "hidden"}}>
                            <div className="inside-upload" style = {{backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "center", position: "relative"}}>
                                {
                                    (uploading) ? 
                                    <div style = {{width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,.33)", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", position: "absolute", zIndex: 1}}>
                                        <p className = "upload-text" style = {{color: "white"}}>Uploading...</p>
                                    </div>
                                    : null   
                                }
                                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                                    {
                                        (uploading) ? 
                                    null : 
                                    <>
                                        <input ref={inputRef} type="file"  accept="image/*" id="input-file-upload" multiple={false} onChange={handleChange} />
                                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                                            <div style = {{display: "flex", flexDirection: "column"}}>
                                                <button className="upload-button" onClick={onButtonClick}>
                                                    <p className = "upload-text">{(file == null) ? "選擇作品" : "重新上載"}</p>
                                                </button>
                                                {
                                                    (file == null) ? null 
                                                :
                                                <button className="upload-button" style={{marginTop:10}} onClick={sendFile}>
                                                    <p className = "upload-text">提交</p>
                                                </button> }
                                            </div> 
                                        </label>
                                        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> } 
                                    </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>  
            }

            <SamsungFooter></SamsungFooter>
        </>
    )
}

export default UplaodImage
