import { useEffect, useState } from "react";
import "./App.css";
import i18next from 'i18next';

const FixedOptions = (props) => {

    const [opacity, setOpacity] = useState(0);
    const [whale, setWhale] = useState(false);
    const [section, setSection] = useState(0);
    
    const [animation, setAnimation] = useState("");
    
    const sections = [
       "活動理念",
       "參與動畫製作",
       "工作坊",
       "環保實踐"
    ]
 
    useEffect(() => {
        let media = window.matchMedia("(max-width: 575.98px)");
        if (media.matches) {
            setAnimation("hide");
        }
    }, []);

    useEffect(() => {
        const handleSize = () => {
            if (window.innerWidth <= 575.98) {
                setAnimation("hideOptions");
                return;
            }
            if (window.innerWidth >= 991.98) {
                setAnimation("");
                return;
            }
        }
        window.addEventListener("resize", handleSize);
        return (() => {
          window.removeEventListener("resize", handleSize);
        })
    }, []);

    useEffect(() => {
        const handleScroll = (event) => {
            
            if (window.pageYOffset <= 200) {
                setOpacity(0);
                setSection(0);
                return;
            }

            if (isInViewport(props.video_ref?.current, 50)) {
                setOpacity(0);
            } 
            
            if (isInViewport(props.presentation?.current, 50)) {
                if (opacity == 1) {
                    return;
                }
                setOpacity(1);
                setSection(0);
            } 
            
            if (isInViewport(props.second_video_ref?.current, 50)) {
                if (opacity == 1) {
                    return;
                }
                setOpacity(1);
                setSection(0);
            } 

            if (isInViewport(props.marquee_ref?.current, 50)) {
                setOpacity(0);
            } 
                     
            if (isInViewport(props.whale_ref?.current, 50)) {
                setOpacity(1);
                setSection(1);
                setWhale(true);
            }else {
                setWhale(false);
            }
            
            if (isInViewport(props.work_ref?.current, 50)) {
                setOpacity(1);
                setSection(2);
            }
            
            if (isInViewport(props.options_ref?.current, 50)) {
                setOpacity(1);
                setSection(3);
                // setSection(2);
            }

       
        }
        window.addEventListener("scroll", handleScroll, true);
    
        return (() => {
          window.removeEventListener("scroll", handleScroll, true);
        })

    }, [opacity]);
    
    const isInViewport = (rect_, percentVisible) => {
        if (rect_) {

            let rect = rect_?.getBoundingClientRect(),
            windowHeight = window.innerHeight;
    
            return !(
                Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
                Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
            )
        }
    };

    const navigate = (index) => {
        if (index == 0) {
            props.presentation?.current.scrollIntoView({behavior: "smooth"});
            return;
        }
        if (index == 1) {
            props.whale_ref?.current.scrollIntoView({behavior: "smooth"});
            return;
        }

        if (index == 2) {
            props.work_ref?.current.scrollIntoView({behavior: "smooth"});
            return;
        }

        if (index == 3) {
            props.options_ref?.current.scrollIntoView({behavior: "smooth"});
            return;
        }

    }

    const chooseOption = () => {
        if (animation == "showOptions") {
            setAnimation("hideOptions");
            return;
        }
        setAnimation("showOptions");
    }
    return (
        <div style = {{opacity: `${opacity}`}} className = {`options-container-animation ${animation}`} >
            {
                (animation == "") ? null :
                <div onClick = {chooseOption} style = {{position: 'absolute', left: "-30%", top: "50%", margin: "auto", transform: "translateY(-50%) translateX(-50%)"}}>
                    <img src = {require("./assets/right.png")} style = {{width: "30px", height: "30px"}}></img>
                </div>
            }
            {
                sections.map((item, index) => {
                    return (
                        <div onClick = {() => navigate(index)} className = {`${true ? 'section-whale-container ' : ''}`} style = {{paddingLeft: "5px", paddingRight: "5px", display: "flex", alignItems: "center", marginBottom: "10px"}}>
                            <div style = {{width: "8px", margin: "10px", height: "8px", borderRadius: "100px", backgroundColor: `${index == section ? '#4db0ff' : 'transparent'}`}}></div>
                            <p className = "section-fixed-text ">{i18next.t(item)}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default FixedOptions;