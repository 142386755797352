import { useRef, useState, useEffect } from "react";
import './App.css';
import './responsive.css';
import ImageSlider from './ImageSlider';
import SamsungNav from './SamsungNav';
import SamsungFooter from './SamsungFooter';
import Marquee from "react-fast-marquee";
import FixedOptions from "./FixedOptions";
import Carousel from 'react-bootstrap/Carousel';
import Lottie from "lottie-react";
import "./Presentation.css";
import "./Video.css";
import "./Experience.css";
import "./FirstCarousel.css";
import Footer from "./Footer";
import "./nav.css";
import "./en.css";
import i18next from 'i18next';
import WorkShopCarousel from "./WorkShopCarousel";
import WorkShopCarousel2 from "./WorkShopCarousel2";

i18next.init({
  lng: 'zh', // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      translation: {
        "zh":"en",
        "香港/繁體中文":"Hong Kong/English",
        "活動理念": "Campaign concept",
        "參與動畫製作": "Be a part of animation production",
        "工作坊": "Workshop",
        "環保實踐": "Green initiatives",
        "共創「您」想大自然" : "Create your own vision of nature",
        "Samsung 一直積極實行可持續的環境管理，從產品規劃到處理廢棄產品等，以盡量減少對環境影響為先。我們期望大家一同響應，於日常每一步作出小改變，逐步實踐環保遠大目標。" : "Samsung is committed to taking the green initiative. With protecting the ecosystem at heart, we implement change through small day-to-day innovations: upcycling old fishing nets to other products*, using 100% recycled paper* packaging to reduce plastic use, developing solar-charged SolarCell remote control to eliminate disposable batteries. We hope to inspire more people to join us in driving change by taking small steps in everyday life.",
        "Samsung 聯同本地環保組織舉辦工作坊，及網上召集大家一人一筆，共創「您」想大自然。被挑選的作品將會組合成 3D 動畫，呈現於中環娛樂行大型 Samsung 屏幕上，希望大家為改善環境踏出更多的每一步。" : "Samsung also collaborates with local environmental organizations to call for public participation in visualizing nature at its best. In the spirit of ‘everyone leaves a brushstroke’, selected works will be used in a 3D animation to feature on a Samsung mega screen outside the Entertainment Building Shopping Arcade in Central.",
        "舊漁網被用於 Galaxy Z Fold5、Z Flip5、S23、Tab S9 和 SolarCell 環保遙控器" : "Old fishing nets are used in Galaxy Z Fold5, ZFlip5, S23, Tab S9 and SolarCell Remote Control",
        "Galaxy Z Fold5、Z Flip5 和 S23 所使用的包裝材料為 100% 回收紙材" : "100% recycled paper material for Galaxy Z Fold5,Z Flip5 and S23",
        "日常小改變": "Small Changes",
        "未來大改善": "Huge Impact",
        "一人一筆，共創「您」想大自然，作品將有機會呈現中環娛樂行大型屏幕": "Leave your own brushstroke by creating your own vision of nature. Selected works will be transformed into a 3D animation to feature on a Samsung mega screen outside the Entertainment Building Shopping Arcade in Central.",
        "簡單三個步驟，即可參與": "Participate in 3 simple steps!",
        "使用 Samsung Galaxy 內置 PENUP 藝術創作應用程式，以獲更多技巧及靈感。":"Use Samsung Galaxy's built-in PENUP arts application to find inspiration and learn drawing techniques.",
        "點擊下方「下載畫紙」":"Click the “Download Drawing Paper” button below",
        "為海洋生物填色繪紋": "Color in the marine creatures",
        "上載作品": "Upload your work",
        "下載「畫紙」": "Download",
        "提交作品": "Submit",
        "繪畫工作坊": "Drawing Workshop",
        "專業插畫師帶領參加者透過 Samsung 最新產品，盡情發揮創意， 共創「您」想大自然。": "Guided by professional illustrators, participants will unleash their creativity, creating their own visions of nature by using the latest Samsung products.",
        "完成畫作後，更可即場印製於環保 Tote Bag 上。": "The works can also be printed onto environmental tote bags on-site.",
        "立即登記": "Register Now",
        "Samsung 會繼續從細微處著手， ": "With your support, Samsung will continue to make small steps",
        "跟大家逐步實踐環保遠大目標" : "toward long-term environmental goals.",
        "再利用": "Repurposing",
        "舊漁網循環再造，製成零件" : "Upcycling old fishing nets for electronic components",
        "減少": "Reduce",
        "SolarCell 環保遙控器，利用陽光或室內光源充電，告別即棄電池" : "SolarCell remote control charged by solar energy or indoor lighting, eliminating disposable batteries",
        "回收": "Recycle",
        "包裝減少使用塑膠及紙材/ 升級再造" : "Reduces use of plastic and paper for packaging",
        "舊漁網被用於 Galaxy Z Fold5、Z Flip5、S23、Tab S9和 SolarCell 環保遙控器" : "Old fishing nets are used in Galaxy Z Fold5, ZFlip5, S23, Tab S9 and SolarCell Remote Control",
        "Galaxy Z Fold5、Z Flip5和S23所使用的包裝材料為100%回收紙材" : "100% recycled paper material for Galaxy Z Fold5,Z Flip5 and S23",
        "深入了解我們所做的每一步": "Learn more about what we do",
        "改變 從日常做起": "",

        "網上商店": "Shop",
        "流動產品": "Mobile",
        "電視影音": "TV & AV",
        "生活家電": "Home Appliances",
        "電腦及周邊": "Computing",
        "顯示器": "Displays",
        "配件": "Accessories",
        "SmartThings": "SmartThings",
        "優惠/活動": "Offers",
        "服務支援": "Support",
        "商務採購": "For Business",

        "© 1995-2023 三星電子香港有限公司 版權所有" : "© 1995-2023 Samsung Electronics H.K. Co., Ltd. All rights reserved.",
        "網站導覽": "Sitemap",
        "私隱政策": "Privacy",
        "使用條款": "Terms of Use",
      }
    }
  }
});

function App() {

  const recycle = [
    {
      type: "舊漁網循環再造，製成零件",
      image: require("./assets/maya.jpg")
    },
    {
      type: "SolarCell 遙控器，利用陽光或室内光源充電告別即棄電池",
      image: require("./assets/remote.jpg")
    },
    {
      type: "包裝減少使用塑膠及紙材",
      image: require("./assets/box.jpg")
    }
  ];

  const disclaimers = [
   "舊漁網被用於 Galaxy Z Fold5、Z Flip5、S23、Tab S9 Ultra、Buds2 Pro 和 SolarCell 環保遙控器",
    "透過易於操作的點陣設計，賦予盒子第二次生命，成為多用途家居用品",
    "Galaxy Z Fold5、Z Flip5和S23所使用的包裝材料為100%回收紙材",
  ];

  const queryParameters = new URLSearchParams(window.location.search)
  const lang = queryParameters.get("lang")
  
  i18next.changeLanguage(lang);

  const dots = [1,2,3];

  const [option, setOption] = useState(0);
  
  const carousel_ref = useRef();
  const video_ref = useRef();
  const options_ref = useRef();
  const second_video_ref = useRef();
  const marquee_ref = useRef();
  const whale_ref = useRef();
  const work_ref = useRef();
  const footer_ref = useRef();
  const presentation_ref = useRef();

  const next = () => {
    carousel_ref.current?.prev();
  }

  const down = () => {
    presentation_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const up = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const runBackground = (option) => {
    setOption(option);
  } 


  return (
    <div className="App" currLang = {i18next.t('zh')} style = {{overflowX: "hidden"}}>
      
      <SamsungNav></SamsungNav>
     
      {/* video */}
      <div
        ref = {video_ref}
        className = "video-container"
      >

        <img src = {require("./assets/top_image.jpg")} style = {{width: "100%", height: "100%"}}>
        </img>

        <div  className="samsung_font" style = {{position: "absolute", textAlign: "center", display: "flex", flexDirection: "column",   alignSelf: "flex-end", width: "100%", justifyContent: "center", zIndex: 1}}>
          <div style = {{width: "100%"}}>
            <p style = {{color: "white", textShadow: "2px 2px 10px black", fontWeight:900, margin: "0px", fontSize: "5vw"}}>
              {i18next.t('共創「您」想大自然')}  
            </p>
          </div>
          <div  style = {{width: "100%", opacity: "0"}}>
            <p style = {{color: "white", textShadow: "2px 2px 10px black", fontWeight:900, fontSize: "4vw", margin: "0px"}}>{i18next.t('改變 從日常做起')}</p>
          </div>
        </div>

        <div onClick = {down} className="down-animation" style = {{width: "30px", height: "30px", position: "absolute", zIndex: 100, bottom: "-60px"}}>
          <img src = {require("./assets/down.png")} style = {{width: "100%", height: "100%"}}></img>
        </div>
        
        <div style = {{position: "absolute", textAlign: "center", display: "flex", alignSelf: "flex-end", width: "100%", justifyContent: "center", background: "linear-gradient(to bottom,  rgba(255,255,255,0), white 100%)", height: "100px", bottom: "0px"}}>
        </div>
       
        {/* image 1*/}
        <div className = "image1">
          <img src = {require("./assets/planta.png")} style = {{width: "100%", height: "100%"}}></img>
        </div>

        {/* image 2*/}
        <div className = "image2">
          <img src = {require("./assets/peces.png")} style = {{width: "100%", height: "100%"}}></img>
        </div>
      
      </div>

      {/* presentation */}
      <div ref = {presentation_ref} className = "container-fluid presentation-container">
        
        {/* gif */}
        <div style = {{position: "absolute", textAlign: "center", display: "flex", flexDirection: "column", alignSelf: "flex-end", width: "100%", justifyContent: "center", zIndex: -1, bottom: "0px"}}>
          <img src = {require("./assets/wave_gif2.gif")} style = {{width: "100%", height: "100%", objectFit: "cover"}}></img>
        </div>

        <div className="presentation-wrapper">
          <div className="inside-presentation-wrapper">
           
            <div className="text-container" style={{paddingRight:50,flexDirection:"column"}}>

              <div>
                <p className = "text-presentation">
                  {i18next.t('Samsung 一直積極實行可持續的環境管理，從產品規劃到處理廢棄產品等，以盡量減少對環境影響為先。我們期望大家一同響應，於日常每一步作出小改變，逐步實踐環保遠大目標。')}
                  <br/><br/>
                  {i18next.t('Samsung 聯同本地環保組織舉辦工作坊，及網上召集大家一人一筆，共創「您」想大自然。被挑選的作品將會組合成 3D 動畫，呈現於中環娛樂行大型 Samsung 屏幕上，希望大家為改善環境踏出更多的每一步。')}  
                </p>
              </div>
              
              <div style = {{marginBottom: "50px"}}>
                <img src = {require("./assets/4_logo.png")} style = {{width: "100%", height: "100%", objectFit: "contain"}}></img>
              </div>
              {/* <div style = {{width: "100%", padding: "10px", backgroundColor: "red"}}>

              </div> */}
            </div>
            <div className="image-container">
              <img src = {require("./assets/child_showing.jpg")}></img> 
            </div>
          </div>
        </div>
       
      </div>

      {/* video */}
      <div 
        ref = {second_video_ref}
        className = "container-fluid video-title-container"
      >
        
        {/* gif */}
        <div style = {{position: "absolute", transform: "rotateX(180deg)",  margin: "auto", left: "0px", textAlign: "center", display: "flex", flexDirection: "column", alignSelf: "flex-end", width: "100%", justifyContent: "center", zIndex: -1, bottom: "0px"}}>
          <img src = {require("./assets/wave_gif2.gif")} style = {{width: "100%", height: "100%", objectFit: "cover"}}></img>
        </div>

        <div className = "video-title-wrapper">
          
          <div className = "title-wrapper">
         
            {/* <p className = "video-title-text" style = {{fontWeight: "bolder"}}> 
              日常小改變
            </p>
            <p className = "video-title-text" style = {{fontWeight: "bolder"}}> 
              未來大改善
            </p> */}
            <p className = "video-title-text" style = {{fontWeight: "bolder"}}> 
            {i18next.t('日常小改變')}
            </p>
            <p className = "video-title-text" style = {{fontWeight: "bolder"}}> 
            {i18next.t('未來大改善')}
            </p>

            <div className = "ostra-image">
              <img src = {require("./assets/ostra.png")}></img>
            </div>
    
            <div className = "raya">
              <img src = {require("./assets/raya.png")} style = {{width: "100%", height: "100%"}}></img>
            </div>
                        
            <div className = "peces">
              <img src = {require("./assets/peces2.png")} style = {{width: "100%", height: "100%"}}></img>
            </div>

          </div>
        
        </div>

        <div className="video-wrapper">

          <div className="video-wrapper-inside">
            
            <video id = "video" width="100%"  loop playsInline controls style = {{borderRadius: "20px"}} poster={require("./assets/video_poster.jpg")}>
              <source src = {require("./assets/video/Samsung_normal.mp4")} type="video/mp4"/>
            </video>
          
            <div className = "pez-espada">  
              <img src = {require("./assets/espada.png")} style = {{width: "100%", height: "100%"}}></img>
            </div>

            <div className = "pez-aleta">
              <img src = {require("./assets/pez.png")} style = {{width: "100%", height: "100%"}}></img>
            </div>

            <div className = "pez-gordito">
              <img src = {require("./assets/pez_gordo.png")} style = {{width: "100%", height: "100%"}}></img>
            </div> 
          
          </div>
    
        </div>
      </div>
   
      {/* marquee */}
      <div ref = {marquee_ref} className = " marquee-height">
           
        <div className = "title-experience-container" style = {{textAlign: "center"}}>
            {/* <p className="title-ex-text" style = {{fontWeight: "bolder"}}> 
                繪畫工作坊
            </p> */}
            <p className="title-ex-text" style = {{fontWeight: "bolder"}}> 
              一人一筆 <br></br>
              共創「您」想大自然作品 
            </p>
        </div>
        
        <Marquee
          play = {true}
          direction = "right"
          speed = {100}
        >
          <div className = "marquee-inside-container">

            <div style = {{height: "100%", display: "flex"}}>
           
              <div className = "marquee-sections">
                
                <div style = {{display: "flex", flex: 1,}}>

                  <div style = {{display: "flex", flex: 1, margin: "10px"}}>
                    <div style = {{width: "100%", height: "100%", borderRadius: "20px", position: "relative"}}>
                      <div className = "marquee-text-container">
                        <p>
                        {i18next.t('一人一筆，共創「您」想大自然，作品將有機會呈現中環娛樂行大型屏幕')}
                        </p>
                      </div>
                      <img src = {require("./assets/party_whale.jpg")} style = {{objectFit: "cover", width: "100%", height: "100%", borderRadius: "20px"}}></img>
                    </div>
                  </div>

                  <div style = {{display: "flex", flex: 3, flexDirection: "column"}}>
                    <div style = {{display: "flex", flex: 1}}>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w1.jpeg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/sit_child.jpg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w3.jpeg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/child_tablet.jpg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w4.jpg")}></img>
                      </div>
                    </div>

                    <div style = {{display: "flex", flex: 1}}>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w5.jpeg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w6.jpeg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/cwb_whale_screen.jpg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w7.jpeg")}></img>
                      </div>
                      <div className = "marquee-images">
                        <img src = {require("./assets/w2.png")}></img>
                      </div>
                    </div>

                  </div>
                                
                </div>
               </div>

              
            </div>
          </div>
        </Marquee>
      </div>

      {/* whale */}
      {/* <ImageSlider
        whale_ref = {whale_ref}
      >
      </ImageSlider> */}
    
      {/* workshop carousel */}
      {/* <WorkShopCarousel work_ref = {work_ref}></WorkShopCarousel> */}
      
      {/* workshop carousel 2 */}
      <WorkShopCarousel2 work_ref = {work_ref}></WorkShopCarousel2>
      
      {/* first carousel */}
      <div ref = {options_ref} className = "container-fluid first-carousel-container-new">
        
        <div className = "first-carousel-presentation">
          
          <div style = {{display: "flex", justifyContent: "center", alignItems: "center",flexDirection: "column", textAlign: "center"}}>
            {/* <p className="first-carousel-text" style = {{margin: "0px", fontWeight: "bolder"}}>
              <p style = {{margin: "0px", display: "inline-block", fontWeight: "bolder"}}>Samsung</p> 會繼續從細微處著手，
            </p>
            <p className="first-carousel-text" style = {{margin: "0px", fontWeight: "bolder"}}>
              跟大家逐步實踐環保遠大目標。
            </p> */}
            <p className="first-carousel-text" style = {{margin: "0px", fontWeight: "bolder"}}>
              <p style = {{margin: "0px", display: "inline-block", fontWeight: "bolder"}}></p> {i18next.t('Samsung 會繼續從細微處著手， ')}
            </p>
            <p className="first-carousel-text" style = {{margin: "0px", fontWeight: "bolder"}}>
            {i18next.t('跟大家逐步實踐環保遠大目標')}
            </p>
          </div>

        </div>

        <FixedOptions
          video_ref = {video_ref}
          presentation = {presentation_ref}
          marquee_ref = {marquee_ref}
          second_video_ref = {second_video_ref}
          whale_ref = {whale_ref}
          work_ref = {work_ref}
          options_ref = {options_ref}
        > 

        </FixedOptions>

        <div style = {{display: "flex", flex: 1, flexDirection: "column"}}>
         
          <div className = "inside-carousel-container">
            {/* images */}
            <div style = {{display: "flex", flex: 1, padding: "10px"}}  className="inside-carousel-wrapper-1">
              
              <div className="carousel-options-container">
                
                <div onClick = {() => runBackground(0)} style = {{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px"}}>
                  <div className = "option-container">
                    {(option == 0) ?
                      <div className = "option-container-behind"></div> : null 
                    } 
                    <img src = {require("./assets/maya.png")}></img>
                    <p style = {{margin: "0px"}}>
                      {i18next.t('舊漁網循環再造，製成零件')}
                    </p>
                  </div>
                </div>

                <div onClick = {() => runBackground(1)} style = {{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px"}}>
                  <div className = "option-container">
                    {(option == 1) ?
                      <div className = "option-container-behind"></div> : null 
                    } 
                    <img src = {require("./assets/pc.png")}></img>
                    <p style = {{margin: "0px"}}>
                    {i18next.t('SolarCell 環保遙控器，利用陽光或室內光源充電，告別即棄電池')}
                    </p>
                  </div>
                </div>

                <div onClick = {() => runBackground(2)} style = {{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px"}}>
                  <div className = "option-container">
                    {(option == 2) ?
                      <div className = "option-container-behind"></div> : null 
                    } 
                    <img src = {require("./assets/box.png")}></img>
                    <p style = {{margin: "0px"}}>
                    {i18next.t('包裝減少使用塑膠及紙材/ 升級再造')}
                    </p>
                  </div>
                </div>
            
              </div>
            </div>
                
            <div style ={{display: "flex", flex: 3, padding: "10px", }}  className="inside-carousel-wrapper-2">
              
              <div style = {{width: "100%", height: "100%", borderRadius: "10px", position: "relative"}}>
                <Carousel
                  indicators = {false}
                  interval = {null}
                  indicatorLabels = {false}
                  controls = {false}
                  ref = {carousel_ref}
                  style = {{
                    width: '100%',
                    height: '100%',
                  }}
                  activeIndex = {option}
                >
                  {
                    recycle.map((item, index) => {
                      return (
                        <Carousel.Item>
                          <div style = {{overflow: "hidden", width: "100%", height: "100%", backgroundImage: `url(${item.image})`, backgroundSize: "cover", backgroundPosition: "center", position: "relative"}}>
                          
                            {/* <div style= {{width: "100%", height: "100%", display: "flex", padding: "10px", position: "absolute", top: "0", padding: "20px", textAlign: "center", flexDirection: "column",}}>
                              <p className="inside-carousel-text">{i18next.t(item.type)}</p>
                            </div> */}
                          </div>
                        </Carousel.Item>              
                      )
                    })
                  }
                </Carousel> 

                <div style = {{width: "100%", display: "flex", padding: "20px", justifyContent: "center", bottom: "0", position: "absolute", flexDirection: "column", alignItems: "flex-start",zIndex:2}}> 

                  <div style = {{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                    {
                      dots.map((item, index) => {
                        return (
                          <div onClick = {() => runBackground(index)} className = {"dots-option"} style = {{backgroundColor: `${(index == option) ? '#4db0ff' : 'white'}`}}>
                          </div>
                        )
                      })
                    }
                  </div>
                 
                  <p style = {{margin: "0px", fontWeight: "bolder", marginBottom: "0px", paddingRight: "50px"}}>
                    {
                      (option == 0) ? 
                      <p className="inside-carousel-text-disclaimers">*{i18next.t(disclaimers[0])}</p> : 
                      (option == 2) ?
                      <>
                        <p className="inside-carousel-text-disclaimers">*{i18next.t(disclaimers[1])}</p> 
                        <p className="inside-carousel-text-disclaimers">*{i18next.t(disclaimers[2])}</p> 
                      </>
                      : 
                      null
                    }
                  </p>

                </div>

              </div>
       
            </div>
          </div>
          
          <div style = {{display: "flex", flex: 1, marginTop: "50px"}}>
           
            <div className = "left-side-bottom-carousel">

            </div>
           
            <div className = "right-side-bottom-carousel">
              <a class = "button-register" style = {{color: "white", padding: "20px", fontWeight: "bolder"}} href = "https://www.samsung.com/hk/sustainability/environment/?cid=hk_paid_Social_Youtube_Sustainability_ongoing_video_Changesfromsmallsteps_none_cold_2&utm_source=Social&utm_medium=Youtube&utm_campaign=Sustainability&utm_term=ongoing_video&utm_content=Changesfromsmallsteps">
                {i18next.t('深入了解我們所做的每一步')}
              </a>
            </div>    
          
          </div>  
        </div>

      </div>
        
      <Footer></Footer>
      <SamsungFooter></SamsungFooter>
      
    </div>
  );
}

export default App;
